<template>
    <div v-if="waited"> {{html}}</div>
    <posts v-else :item0="item0" :items="items" />
</template>

<script>
// @ is an alias to /src
import axios from 'axios';
import Posts from '@/components/Posts.vue'

export default {
  name: 'Home',
  components: {
     Posts
  },

  data() {
    return {
          html: "Chargement...",
          items : [],
          item0 : {},
          waited : true      
    }
  },
    created : function() {
      var baseurl = "/api/"
      //  var baseurl = "/data"
      axios.get(baseurl+'?type=presentation&num=all').then(response =>{
        
        console.log(response.data);

        this.items = response.data;
        this.items.reverse();
        this.item0 = this.items.pop();
        this.items.reverse();

        this.waited=false;

     }).catch(error => {
         this.html=error;
     })
  }

}
</script>