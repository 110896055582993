import "bootstrap/dist/css/bootstrap.min.css"

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'


import "./assets/css/main.css"

import "./assets/js/util.js";
import "./assets/js/main.js";


createApp(App).use(router).mount('#app')

import "bootstrap/dist/js/bootstrap.js"


