<template>
  <div id="main">
    <Post   :label="item0['label']" 
            :msg="item0['msg']" 
            :titre="item0['titre']" 
            :link="item0['link']" 
            :img="item0['img']" 
            :isfirst="true"
            :visible="item0['visible']" 
            :editable="item0['editable']" />

    <section class="posts">		
      <Post v-for="(item,index) in items" :key='index' :label="item['label']" 
                    :msg="item['msg']" 
                    :titre="item['titre']" 
                    :link="item['link']" 
                    :img="item['img']" 
                    :isfirst="false"
                    :visible="item['visible']" 
                    :editable="item['editable']" />

    </section>
    </div>
</template>

<script>
// @ is an alias to /src
import Post from '@/components/Post.vue'

export default {
  name: 'Posts',
  components: {
     Post
  },
  props: {
    item0 : Object,
    items: Object
  }
}
</script>
<style scoped>
</style>