import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/gazettes',
    name: 'Gazettes',
    component: () => import(/* webpackChunkName: "gazettes" */ '../views/Gazettes.vue')
  },
  {
    path: '/article/:rep/:num/:editable',
    name: 'Article',
    component: () => import(/* webpackChunkName: "gazette" */ '../components/Article.vue'),
    props:true
  },
  {
    path: '/outillage',
    name: 'Outillage',
    component: () => import(/* webpackChunkName: "evenements" */ '../views/Outillage.vue')
  },
  {
    path: '/Evenements',
    name: 'Evenements',
    component: () => import(/* webpackChunkName: "evenements" */ '../views/Evenements.vue')
  },
  {
    path: '/Contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "identification" */ '../views/Identification.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
