<template>
		<!-- Wrapper -->
			<div id="wrapper" class="fade-in">        
				<!-- Header -->
					<header id="header">
						<a href="index.html" class="logo"><img src="./assets/images/labobinette_banniere.png" /></a>
					</header>

				<!-- Nav -->
        <navigation /> 
        <router-view/>
      </div>
 
</template>

<script>

import Navigation from "@/components/Navigation.vue"

export default {
  name: 'App',
  components : {Navigation},
  data() {
    return {
      selected: 'Home'
    }
  },
  methods: {
    setSelected(tab) {
      this.selected = tab;
    }
  }
}
</script>

<style>


</style>



