<template>
    <article v-if="visible" :class="'post '+ featured">
        <header :class="major">
            <span class="date">{{label}}</span>
            <h2><router-link :to="link+'/'+editable" >{{titre}}</router-link></h2>
            <p>{{msg}}</p>
        </header>
       <router-link :to="link+'/'+editable" :class="'image ' + imgclass">
          <img :src="img" alt="" />
        </router-link>
        <ul class="actions special">
            <li><router-link :to="link+'/'+editable" class="button large">Plus</router-link></li>
        </ul>
    </article>
</template>

<script>
export default {
  name: 'Post',

  props: {
	label : String,
	msg: String,
	titre:String,
	link: String,
	isfirst: Boolean,
	img:String,
    editable:Boolean,
    visible:Boolean
  },

  data() {
      return {
          featured:"",
          major:"",
          imgclass:"fit"
      }
  },

  created : function() {
      if (this.isfirst) {
          this.featured="featured";
          this.major="major";
          this.imgclass="main";
      }      
  }
}
</script>