<template>
					<!--<nav id="nav" >
						<ul class="links">
							<li><router-link to="/">Accueil</router-link></li>
							<li><router-link to="/gazettes">Gazettes</router-link></li>
							<li><router-link to="/evenements">Les événements</router-link></li>
							<li><router-link to="/contact">Nous rejoindre</router-link></li>
						</ul>
						<ul class="icons">
							<li><a href="#" class="icon brands fa-twitter"><span class="label">Twitter</span></a></li>
							<li><a href="#" class="icon brands fa-facebook-f"><span class="label">Facebook</span></a></li>
							<li><a href="#" class="icon brands fa-instagram"><span class="label">Instagram</span></a></li>
						</ul>
					</nav>-->
          <nav class="navbar navbar-expand-lg navbar-light nav-tabs ">
            <router-link to="/" class="navbar-brand" href="#">Labo binette</router-link>
              <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto links">
                  <li class="nav-item active">
                    <router-link to="/" class="nav-link" href="#">Accueil <span class="sr-only">(current)</span></router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/gazettes" class="nav-link" href="#">Gazettes</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/evenements" class="nav-link" href="#">Les événements</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/outillage" class="nav-link" href="#">Outillage</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/contact" class="nav-link" href="#">Nous rejoindre</router-link>
                  </li>                  
                </ul>
                <ul class="icons">
                  <li><router-link to="/login" class="icon brands"> <img src="@/assets/icons/login-circle-fill36.png" alt="se connecter"/></router-link></li>
                  <li><a href="https://www.facebook.com/Labo-Binette-102602332347255" class="icon brands fa-facebook-f"><span class="label">Facebook</span></a></li>                  
                  <!--<li><a href="#" class="icon brands fa-twitter"><span class="label">Twitter</span></a></li>
                  <li><a href="#" class="icon brands fa-instagram"><span class="label">Instagram</span></a></li>-->
                </ul>

              </div>
          </nav>

</template>

<script>
export default {
  name: 'Navigation',
}
</script>
<style scoped>
nav {
  background-color : rgba(227, 230, 52, 0.175);
  font-family: "Source Sans Pro", Helvetica, sans-serif;
  letter-spacing: 0.075em;
  list-style: none;
  text-transform: uppercase;

}

	ul.icons {
		cursor: default;
		list-style: none;
		padding-left: 0;
	}

		ul.icons li {
			display: inline-block;
			padding: 0 0.5rem 0 0;
			vertical-align: middle;
		}

			ul.icons li:last-child {
				padding-right: 0;
			}

			ul.icons li .icon:before {
				width: 2.25rem;
				height: 2.25rem;
				line-height: 2.25rem;
				display: inline-block;
				text-align: center;
				border-radius: 100%;
				font-size: 1.25rem;
			}

		ul.icons.alt li .icon:before {
			-moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
			-webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
			-ms-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
			transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
			font-size: 1rem;
		}


.icon {
text-decoration: none;
border-bottom: none;
position: relative;
}

.icon:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  text-transform: none !important;
  font-family: 'Font Awesome 5 Free';
  font-weight: 400;
}

.icon > .label {
  display: none;
}

.icon:before {
  line-height: inherit;
}

.icon.solid:before {
  font-weight: 900;
}

.icon.brands:before {
  font-family: 'Font Awesome 5 Brands';
}

@import url("../assets/css/fontawesome-all.min.css");
@import url("https://fonts.googleapis.com/css?family=Merriweather:300,700,300italic,700italic|Source+Sans+Pro:900");


</style>